.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  pointer-events: none;
  height: 10vh;
  width: auto;
}

.App-header {
  background-color: white;
  min-height: 10vh;
  max-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: lightgray;
  flex-direction: row;
  padding: 5vh;
  height: 70vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.User-body {
  height: 60vh;
  width: 10vw;
  padding-right: 1%;
}

.Message-body {
  height: 60vh;
  width: 50vw;
}